import { Suspense, useEffect, useState } from "react";
import { sanityClient } from "../../../client";
import {PortableText} from '@portabletext/react'

export default function HomeContent() {
    const [text, setText] = useState([] as any[])

  useEffect(() => {
		sanityClient
      .fetch(
				`*[_type == "home-page"]{
            text,
        }`
			)
      .then((data) => data.map((d: any) => {
        return setText(d.text);
      }))
			.catch(console.error);
	}, []);
  return (
    <>
       <Suspense fallback={<div className="container">Loading...</div>}>
      <span className="Inner-panel w-full md:w-1/2 p-8 animate-fadeIn">
        <span className='w-full text-md md:text-lg'>
          <PortableText value={text} />
        </span>
      </span>
      </Suspense>
    </>
  )
}
