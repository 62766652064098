import { useEffect, useState } from "react";
import { sanityClient } from "../client";
import ContentPhoto from "../components/Content/ContentPhoto";
import FlipCard from "../components/FlipCard";

export default function OurServices() {
  const [services, setServices] = useState([] as any[])
  const [imageUrl, setImageUrl] = useState('' as string)

  useEffect(() => {
		sanityClient
      .fetch(
				`*[_type == "our-services-page"]{
            'services': services,
            'url' : image.asset->url
        }`
    ).then((data) => data.map((d: any) => {
      setServices(d.services);
        return setImageUrl(d.url);
      }))
			.catch(console.error);
	}, []);
  return (
      <>
      <span className='Container flex-col md:flex-row h-auto md:h-[80vh]'>
        <span className="w-2/4 pt-20 md:pt-0 animate-fadeInDown1">
          <ContentPhoto imageUrl={imageUrl} />
        </span>
        <span className="grid gap-0 grid-cols-1 md:grid-cols-2 w-full h-full p-8">
          {
            services.map((service: any) => {
              return <span key={service._key} className={`w-auto h-60 md:h-auto border-2 border-y-stone-500 animate-fadeInDown1`}>
                <FlipCard title={service.title} description={service.description} />
              </span>
            })
          }
        </span>
        </span>
    </>
  )
}
