export default function FooterPanel() {
  return (
    <>
      <span className="Footer-panel flex-col justify-between space-y-4 md:space-y-0 p-4 md:flex-row">
        <span id='Dermatologist_Skin_Company_Registration' className="flex items-center justify-center">
          <p>
            DR JEANNETT MAEPA
            <br/>MBChB (UKZN) Fc DERM (SA) MMED (UFS) Practice no: 1094092
          </p>
        </span>
        <span id='Dermatologist_Skin_Contact' className="flex items-center justify-center">
          <p>
            T
            Call: 015 001 1807
            <br/>Emergency: 081 556 5055
            <br />64 Plein street, Kay Medical Centre (2nd floor)
            <br />Polokwane
            <br/>0699
          </p>
        </span>
        <span id='nolo_cloud' className="flex items-center justify-center">
          <p>by <a className="hover:cursor-pointer hover:text-green-700" href="https://www.linkedin.com/in/thulomaepa/" target="_blank" rel="noopener noreferrer">
            Thulo Maepa</a>
          </p>
        </span>
        </span>
    </>
  )
}
