import { useEffect, useState } from "react";
import ContentPanel from "../components/Content/ContentPanel";
import { sanityClient } from "../client";

export default function ContactUs() {
  const [text, setText] = useState([] as any[])
  const [imageUrl, setImageUrl] = useState('' as string)

  useEffect(() => {
		sanityClient
      .fetch(
				`*[_type == "contact-us-page"]{
            text,
            'image' : image.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setText(d.text);
        return setImageUrl(d.image);
      }))
			.catch(console.error);
	}, []);
  return (
      <>
      <span className='Container h-full'>
        <ContentPanel text={text} imageUrl={imageUrl} />
        </span>
    </>
  )
}
