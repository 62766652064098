import HomeActionPanel from "../components/Pages/Home/HomeActionPanel";
import HomeContentPanel from "../components/Pages/Home/HomeContentPanel";

export default function Home() {
return (
    <>
      <span className='Container h-auto w-full flex-col md:flex-row md:pt-0'>
            <HomeActionPanel/>
            <HomeContentPanel/>
        </span>
    </>
)
}
