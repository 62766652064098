
export default function MapContainer(props: any) {
    const { heading, mapFrame, number, addressText } = props;
  return (
    <span className='flex flex-col items-center justify-center p-6 space-y-4 animate-fadeInDown15'>
      <p className="flex font-semibold">{heading}</p>
      <p className="flex text-sm">{ addressText }</p>
        <span className='w-full md:w-[70%] h-full'>
            {mapFrame}
        </span>
          <p> Call : {number}</p>
    </span>
  )
}
