
import { PortableText } from '@portabletext/react'

export default function FlipCard(props: any) {
    const { title, description } = props;
          return (
        <span className="flip-card justify-center items-center w-auto h-auto">
            <span className="flip-card-inner flex justify-center items-center">
                <span className="flip-card-front flex justify-center items-center">
                          <h1 className="text-2xl font-bold">{title}</h1>
                </span>
                <span className="flip-card-back p-2">
                  <span className="text-xs lg:text-sm">
                    <PortableText value={description} />
                  </span>
            </span>
         </span>
</span>   
  )
}
