import { Suspense } from "react";

export default function ContentPhoto(props: any) {
  const { imageUrl } = props;
  return (
    <>
        <span className='flex justify-center items-center w-full h-full p-4 animate-fadeInDown05'>
       <Suspense fallback={<div className="container">Loading...</div>}>
          <img className="object-cover max-h-[75.2vh]" alt="Dermatologist_Skin_content_photo" src={imageUrl} />
        </Suspense>
        </span>
    </>
  )
}
