import ContentText from './ContentText'
import ContentPhoto from './ContentPhoto'
import MapContainer from '../Maps/MapContainer';

export default function ContentPanel(props: any) {
  const { imageUrl, text } = props;
  const polokwaneFrame = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3647.5342203990776!2d29.459899084649972!3d-23.9061299232945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ec6d9d4ce6545f3%3A0x3fba246704612353!2sKay%20Medical%20Centre!5e0!3m2!1sen!2sza!4v1690216909356!5m2!1sen!2sza" width="100%" height="100%" className='' allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>;
  const polokwaneAddressText = '64 Plein street, Kay Medical Centre (2nd floor) Polokwane';
  const pretoriaFrame = <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.1339443798847!2d28.219623215021!3d-25.766138983634765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95619c3eebcc67%3A0x2bc3d61ec8dae249!2sAdvanced%20Groenkloof%20Day%20Hospital!5e0!3m2!1sen!2sza!4v1690217306331!5m2!1sen!2sza" width="100%" height="100%" className='' allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>;
  const pretoriaAddressText = 'Advanced Groenkloof Day Hospital, 90 Florence Ribeiro Ave Muckleneuk';
  return (
    <>
      <span className='flex flex-col md:flex-row justify-center items-center w-full h-full'>
          <ContentPhoto imageUrl={imageUrl} />
        <span className='flex flex-col w-full h-full p-4 space-y-4'>   
              <MapContainer heading='POLOKWANE' addressText={polokwaneAddressText} mapFrame={ polokwaneFrame} number='015 001 1807'/>              
              <MapContainer heading='PRETORIA'addressText={pretoriaAddressText}  mapFrame={ pretoriaFrame}  number='012 001 1554' />
              <ContentText text={text} />
        </span>
      </span>
    </>
  )
}
