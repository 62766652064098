import NavigationBar from "./NavigationBar";

export default function NavigationPanel() {
  return (
    <>
      <span className='Navigation-panel'>
        <NavigationBar/>
        </span>
    </>
  )
}
