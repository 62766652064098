import { useEffect, useState } from "react";
import { sanityClient } from "../client";
import ContentPhoto from "../components/Content/ContentPhoto";
import ContentText from "../components/Content/ContentText";
export default function AboutUs() {
  const [text, setText] = useState([] as any[])
  const [imageUrl, setImageUrl] = useState('' as string)

  useEffect(() => {
		sanityClient
      .fetch(
				`*[_type == "about-us-page"]{
            text,
            'image' : image.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setText(d.text);
        return setImageUrl(d.image);
      }))
			.catch(console.error);
	}, []);
  return (
      <>
      <span className='Container flex-col md:flex-row p-16'>
        <span className="w-full md:w-1/2 h-full md:h-1/2">
          <ContentPhoto imageUrl={imageUrl} />
        </span>
        <span className="w-full md:w-1/2 h-full md:h-1/2">
          <ContentText text={text} />
        </span>
        </span>
    </>
  )
}
