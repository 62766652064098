import { Suspense, useEffect, useState } from "react";
import { sanityClient } from "../../../client";
import { Carousel } from "@material-tailwind/react";

export default function HomeActionPanel() {
  const [images, setImages] = useState([] as any[])

  useEffect(() => {
		sanityClient
      .fetch(
				`*[_type == "home-page"]{
            text,
            'image' : images[]{ 
              'url':asset->url
            },
        }`
			)
      .then((data) => data.map((d: any) => {
        return setImages(d.image);
      }))
			.catch(console.error);
	}, []);
  return (
    <>
       <Suspense fallback={<div className="container">Loading...</div>}>
      <span className="Inner-panel w-full h-[40vh] md:h-[80vh]">
        <span className="absolute flex flex-col">
          <h1 className="text-5xl animate-fadeInDown05 md:text-9xl text-white z-50 bg-blend-darken">Clear</h1>
          <h1 className="text-5xl animate-fadeInDown1 md:text-9xl text-white z-50 bg-blend-darken">Healthy</h1>
          <h1 className="text-5xl animate-fadeInDown15 md:text-9xl text-white z-50 bg-blend-darken">Skin</h1>
        </span>
        <Carousel className="flex items-center justify-center w-full h-full rounded-xl brightness-50">
              {
              images?.map((image: any) => {
                    return <img key={image} src={image.url} alt="Dermatologist_Skin_content_photo" className="h-full w-full object-cover"/>
                  })
              }
        </Carousel>
        </span>
        </Suspense>
    </>
  )
}
