import { useEffect, useState } from "react";
import { sanityClient } from "../client";


export default function LogoPanel() {
  const [logo, setLogo] = useState('' as string)
  useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "general"]{
            mainImage {
            asset->{
              _id,
              url
            },
          },
        }`
			)
			.then((data) => setLogo(data[0].mainImage.asset.url))
			.catch(console.error);
	}, []);

  return (
      <>
      <span className={`absolute animate-fadeInDown15 top-4 left-4 z-10 transition-all duration-200 opacity-100 md:top-8 md:left-8 h-32 w-32 md:h-30 md:w-30 lg:w-52 lg:h-52`}>
        <img className="w-full h-full rounded-full" src={logo} alt="Dermatologist_Skin_logo" />
        </span>
      </>
  )
}
