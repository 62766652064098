
import { PortableText } from '@portabletext/react'
import { Suspense } from 'react';

export default function ContentText(props: any) {
  const { text } = props;
  
  return (
    <>
       <Suspense fallback={<div className="container">Loading...</div>}>
        <span className='flex flex-col w-full h-full text-md md:text-xl animate-fadeInDown15'>
          <PortableText value={text} />
        </span>
        </Suspense>
    </>
  )
}
