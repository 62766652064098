import './App.css';
import { Routes, Route } from 'react-router-dom';
import NavigationPanel from './components/Navigation/NavigationPanel';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import OurServices from './Pages/OurServices';
import NoMatch from './Pages/NoMatch';
import { Suspense } from 'react';
import LogoPanel from './components/LogoPanel';
import FooterPanel from './components/Footer/FooterPanel';
import FloatingWhatsappContainer from './components/Contact/FloatingWhatsappContainer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavigationPanel />
        <LogoPanel/>
      </header>
      <span className="App-body">
       <Suspense fallback={<div className="container">Loading...</div>}>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/OurServices" element={<OurServices />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </span>
      <footer className='App-footer mt-6 md:mt-0'>
        <FooterPanel/>
      </footer>
      <span className='fixed bottom-16 right-16 animate-fadeInUp05'>
        <FloatingWhatsappContainer />
        </span>
    </div>
  );
}

export default App;
